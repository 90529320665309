import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import userReducer from '../../features/users/userSlice';
import missionsReducer from '../../pages/missions/missionsSlice';
import fleetReducer from '../../pages/fleet/fleetSlice';
import infrastructureReducer from '../../pages/infrastructure/infrastructureSlice';
import { userApi } from '../../services/userApi';

const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    user: userReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
    missionsStore: missionsReducer,
    fleet: fleetReducer,
    infrastructure: infrastructureReducer,

    // Add the generated reducer as a specific top-level slice
    [userApi.reducerPath]: userApi.reducer,
});

export const createStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware),
        preloadedState,
    });

export const store = createStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
