import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../configuration/setup/store';
import { VehicleData } from '../../types';

export interface FleetState {
    vehicles: VehicleData[];
}

const initialState: FleetState = {
    vehicles: [],
};

const fleetSlice = createSlice({
    name: 'fleet',
    initialState,
    reducers: {
        storeVehicles: (state, action: PayloadAction<VehicleData[]>) => {
            state.vehicles = action.payload;
        },
    },
});

export const { storeVehicles } = fleetSlice.actions;

export const getVehicles = (state: RootState) => state.fleet.vehicles ?? 'NO_VEHICLES_AVAILABLE';

export default fleetSlice.reducer;
